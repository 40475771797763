import axios from 'axios';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL 
export const handleLogout = async () => {
  try {
    // Assuming your server's logout endpoint is '/api/auth/logout'
    await axios.post(`${API_BASE_URL}/api/auth/logout`, {}, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}` // Pass the token in the request header
      }
    });

    // Remove the token from local storage after successful logout
    localStorage.removeItem('token');

    // You can add more cleanup actions here if needed
  } catch (error) {
    console.error('Logout failed:', error);
    throw error; // Rethrow the error to handle it in the component
  }
};
