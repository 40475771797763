import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { MdDeleteForever, MdArrowBackIos } from "react-icons/md";
import { FaEdit, FaFileAlt, FaFolderOpen, FaRegPlusSquare } from 'react-icons/fa';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import 'react-toastify/dist/ReactToastify.css';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import '../styles/Dashboard.css';
import '../styles/Sidebar.css';
import '../styles/MyCampaing.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const MyCampaigns = () => {
    const [projects, setProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filter, setFilter] = useState('');
    const [selectedProject, setSelectedProject] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(6);
    const [totalPages, setTotalPages] = useState(0);
    const excelUrlRef = useRef(null);
    const [showExcelUrlModal, setShowExcelUrlModal] = useState(false);
    const [showUserListModal, setShowUserListModal] = useState(false);
    const [showUserFilesModal, setShowUserFilesModal] = useState(false);
    const [currentUserFiles, setCurrentUserFiles] = useState([]);
    const [currentView, setCurrentView] = useState('userList');
    const [selectUser, setSelectUser] = useState('');
    const location = useLocation();
    const selectedProjectId = location.state?.selectedProjectId;
    const [wipData, setWipData] = useState({ links: [], participants: [] });
    const [showWipModal, setShowWipModal] = useState(false);
    const [showAddLinkModal, setShowAddLinkModal] = useState(false);
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [projectToDelete, setProjectToDelete] = useState(null);
    const [holdStart, setHoldStart] = useState(0);
    const [newLinkName, setNewLinkName] = useState('');
    const [newLink, setNewLink] = useState('');

    const handleUserListClick = (e) => {
        e.stopPropagation();
        setShowUserListModal(true);
    };

    const handleUserSectionClick = async (userId) => {
        if (!selectedProject) return;
        try {
            const response = await axios.get(`${API_BASE_URL}/api/projects/get-files/${selectedProject._id}/user-files/${userId}`, { withCredentials: true });
            setCurrentUserFiles(response.data.files);
            setCurrentView('fileList');
        } catch (error) {
            console.error('Error fetching files for user:', error);
            toast.error('Failed to fetch files for user.');
        }
    };

    const downloadFile = (filePath) => {
        const fileName = filePath.split('\\').pop();
        const url = `${API_BASE_URL}/${filePath}`;
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const startHold = () => {
        setHoldStart(Date.now());
        const fillElement = document.querySelector('.confirm-delete-fill');
        if (fillElement) {
            fillElement.style.width = '100%';
        }
    };

    const endHold = () => {
        const fillElement = document.querySelector('.confirm-delete-fill');
        const holdDuration = Date.now() - holdStart;

        if (holdDuration >= 2000) {
            handleDeleteConfirm();
        }

        if (fillElement) {
            fillElement.style.width = '0%';
        }
        setHoldStart(0);
    };

    const handleDeleteConfirm = async () => {
        try {
            await axios.delete(`${API_BASE_URL}/api/projects/delete-project${projectToDelete}`, { withCredentials: true });
            toast.success('Project deleted successfully!');
            setShowDeleteConfirmModal(false);
            setSelectedProject(null);
            fetchProjects();
        } catch (error) {
            console.error('Error deleting project:', error);
            toast.error('Failed to delete project.');
        }
    };

    const handleDeleteProjectClick = (e, projectId) => {
        e.stopPropagation();
        setProjectToDelete(projectId);
        setShowDeleteConfirmModal(true);
    };

    const navigate = useNavigate();

    const handleUserNameClick = (userName) => {
        const [firstName, lastName] = userName.split(' ');
        navigate(`/dashboard/Profile?firstName=${firstName}&lastName=${lastName}`);
    };

    const handleFileAttach = async (e, taskIndex) => {
        const files = e.target.files;
        if (files.length === 0) return;
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('originalNames', encodeURIComponent(files[i].name));
        }
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }

        if (!selectedProject) return;
        
        formData.append('projectId', selectedProject._id);
        if (taskIndex !== undefined && selectedProject.tasks[taskIndex]) {
            formData.append('userName', selectedProject.tasks[taskIndex].userName);
            formData.append('userId', selectedProject.tasks[taskIndex]._id);
        }

        try {
            await axios.put(`${API_BASE_URL}/api/projects/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true
            });
            toast.success('File uploaded successfully!');
        } catch (error) {
            console.error('Error uploading file:', error);
            toast.error('Failed to upload file.');
        }
    };

    const fetchProjects = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${API_BASE_URL}/api/projects/my-campaign`, {
                params: {
                    filter,
                    page: currentPage,
                    pageSize: currentPage === 1 ? pageSize - 1 : pageSize
                },
                withCredentials: true
            });
            setProjects(response.data.projects);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error('Failed to fetch projects:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchWipData = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/projects/wip`, { withCredentials: true });
            setWipData(response.data && response.data.links ? response.data : { links: [], participants: [] });
        } catch (error) {
            console.error('Failed to fetch WIP data:', error);
        }
    };

    useEffect(() => {
        if (selectedProjectId && projects.length > 0) {
            const selectedProject = projects.find(project => project._id === selectedProjectId);
            setSelectedProject(selectedProject);
        }
    }, [selectedProjectId, projects]);

    useEffect(() => {
        fetchProjects();
        fetchWipData();
    }, [filter, currentPage]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleDeleteFile = async (fileId, index) => {
        try {
            const response = await axios.delete(`${API_BASE_URL}/api/projects/${selectedProject._id}/delete-file/${fileId}`, {
                withCredentials: true
            });

            if (response.status === 200) {
                const updatedFiles = [...currentUserFiles];
                updatedFiles.splice(index, 1);
                setCurrentUserFiles(updatedFiles);
                toast.success('File deleted successfully!');
            } else {
                toast.error('Failed to delete file.');
            }
        } catch (error) {
            console.error('Error deleting file:', error);
            toast.error('Failed to delete file.');
        }
    };

    const handleStatusChange = async (e, taskIndex, userId, taskId) => {
        const newStatus = e.target.value;
        const previousStatus = selectedProject.tasks[taskIndex].status;

        const updatedTasks = selectedProject.tasks.map((task, index) => {
            if (index === taskIndex) {
                return { ...task, status: newStatus };
            }
            return task;
        });

        const updatedProjectStatus = determineProjectStatus(updatedTasks);
        const updatedSelectedProject = { ...selectedProject, tasks: updatedTasks, status: updatedProjectStatus };
        setSelectedProject(updatedSelectedProject);

        const updatedProjects = projects.map(project => {
            if (project._id === selectedProject._id) {
                return updatedSelectedProject;
            }
            return project;
        });
        setProjects(updatedProjects);
        try {
            await axios.post(`${API_BASE_URL}/api/projects/update-task-status`, {
                projectId: selectedProject._id,
                userId: userId,
                newStatus: newStatus,
            }, { withCredentials: true });

            toast.success('Task status updated successfully!');
        } catch (error) {
            setSelectedProject({ ...selectedProject, tasks: selectedProject.tasks.map((task, index) => {
                if (index === taskIndex) {
                    return { ...task, status: previousStatus };
                }
                return task;
            }), status: determineProjectStatus(selectedProject.tasks) });

            setProjects(projects.map(project => {
                if (project._id === selectedProject._id) {
                    return { ...project, status: determineProjectStatus(selectedProject.tasks) };
                }
                return project;
            }));
            if (error.response && error.response.data && error.response.status === 403) {
                toast.error(error.response.data.error);
            } else {
                toast.error('An error occurred while logging in.');
            }
            const revertedTasks = selectedProject.tasks.map((task, index) => {
                if (index === taskIndex) {
                    return { ...task, status: previousStatus };
                }
                return task;
            });
            setSelectedProject({ ...selectedProject, tasks: revertedTasks });
        }
    };

    const updateExcelUrl = async (projectId, excelUrl) => {
        try {
            await axios.put(`${API_BASE_URL}/api/projects/update-excel-url`, {
                projectId,
                excelUrl
            }, { withCredentials: true });
            toast.success('Excel URL updated successfully!');
            setSelectedProject(prevProject => ({
                ...prevProject,
                excelUrl: excelUrl
            }));
            setShowExcelUrlModal(false);
        } catch (error) {
            console.error('Error updating Excel URL:', error);
            toast.error('Failed to update Excel URL.');
        }
    };

    const determineProjectStatus = (tasks) => {
        const allDone = tasks.every(task => task.status === 'Done');
        const anyProblem = tasks.some(task => task.status === 'Problem');

        if (allDone) return 'Done';
        if (anyProblem) return 'Problem';
        return 'Not Done';
    };

    const addWipLink = async (url, name) => {
        try {
            const response = await axios.post(`${API_BASE_URL}/api/projects/wip/add-link`, { url, name }, { withCredentials: true });
            setWipData(response.data);
            toast.success('Link added successfully!');
        } catch (error) {
            console.error('Error adding WIP link:', error);
            toast.error('Failed to add link.');
        }
    };

    const deleteWipLink = async (linkId) => {
        try {
            const response = await axios.delete(`${API_BASE_URL}/api/projects/wip/delete-link/${linkId}`, { withCredentials: true });
            setWipData(response.data);
            toast.success('Link deleted successfully!');
        } catch (error) {
            console.error('Error deleting WIP link:', error);
            toast.error('Failed to delete link.');
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const getStatusIndicatorClass = (status) => {
        switch (status) {
            case 'Done':
                return 'status-indicator green';
            case 'Not Done':
                return 'status-indicator red';
            case 'Problem':
                return 'status-indicator orange';
            default:
                return 'status-indicator';
        }
    };

    const displayProjects = () => {
        const displayProjects = projects;
        return (
            <>
                {currentPage === 1 && wipData && (
                    <div className='My-campaign-cards' onClick={() => setShowWipModal(true)}>
                        <div className="circular-progress-container">
                            <CircularProgressbar
                                value={100}
                                text={`WIP`}
                                background
                                backgroundPadding={6}
                                styles={{
                                    root: {},
                                    path: {
                                        stroke: `var(--color-primary)`,
                                        strokeLinecap: 'round',
                                        transition: 'stroke-dashoffset 0.5s ease 0s',
                                    },
                                    trail: {
                                        stroke: 'var(--color-info-light)',
                                        strokeLinecap: 'round',
                                    },
                                    text: {
                                        fill: 'var(--color-dark)',
                                        fontSize: '16px',
                                        dominantBaseline: 'middle',
                                        textAnchor: 'middle',
                                    },
                                    background: {
                                        fill: 'var(--color-background)',
                                    },
                                }}
                            />
                        </div>
                        <div className={`tag status-wip`}>
                            WIP
                        </div>
                        <div className="content">
                            <div className="center">
                                <h3>Work in Progress</h3>
                                <p>Documents and tasks currently in progress.</p>
                            </div>
                        </div>
                        <div className="footer">
                            <div className="profile-images-container">
                                {/* You can add profile images or any other information here */}
                            </div>
                        </div>
                    </div>
                )}
                {displayProjects.map((project, index) => (
                    <div key={index} className='My-campaign-cards' onClick={() => setSelectedProject(project)}>
                        <div className="circular-progress-container">
                            <CircularProgressbar
                                value={project.tasks.filter(task => task.status === "Done").length / project.tasks.length * 100}
                                text={`${Math.round(project.tasks.filter(task => task.status === "Done").length / project.tasks.length * 100)}%`}
                                background
                                backgroundPadding={6}
                                styles={{
                                    root: {},
                                    path: {
                                        stroke: `var(--color-primary)`,
                                        strokeLinecap: 'round',
                                        transition: 'stroke-dashoffset 0.5s ease 0s',
                                    },
                                    trail: {
                                        stroke: 'var(--color-info-light)',
                                        strokeLinecap: 'round',
                                    },
                                    text: {
                                        fill: 'var(--color-dark)',
                                        fontSize: '16px',
                                        dominantBaseline: 'middle',
                                        textAnchor: 'middle',
                                    },
                                    background: {
                                        fill: 'var(--color-background)',
                                    },
                                }}
                            />
                        </div>
                        <div className={`tag status-${project.status.toLowerCase().replace(/\s+/g, '-')}`}>
                            {project.status.toUpperCase()}
                        </div>
                        <div className="content">
                            <div className="center">
                                <h3>{new Date(project.deadline).toLocaleDateString()}</h3>
                                <h1>{project.projectName}</h1>
                                <p>{project.description}</p>
                            </div>
                        </div>
                        <div className="footer">
                            <div className="profile-images-container">
                                {project.tasks.map((task, taskIndex) => (
                                    <img
                                        key={taskIndex}
                                        src={`${API_BASE_URL}/${task.profileImage}`}
                                        alt="Profile"
                                        style={{
                                            '--img-index': `${project.tasks.length - taskIndex}`,
                                            width: '30px',
                                            height: '30px',
                                            borderRadius: '50%',
                                            marginLeft: taskIndex > 0 ? '-15px' : '0',
                                        }}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </>
        );
    };

    return (
        <div className={`MyCampaign ${selectedProject ? 'blur' : ''}`}>
            <div className='My-campaign-page-header'>
                <h1>My WORK</h1>
                <select onChange={(e) => {
                    setFilter(e.target.value);
                    setCurrentPage(1);
                }} value={filter} style={{ float: 'right' }}>
                    <option value="">All</option>
                    <option value="Done">Done</option>
                    <option value="Not Done">Not Done</option>
                    <option value="Problem">Problem</option>
                </select>
            </div>
            <div className='campaign-container'>
                {displayProjects()}
            </div>
            <div className="pagination-controls">
                <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                <span>Page {currentPage} of {totalPages}</span>
                <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage >= totalPages}>Next</button>
            </div>

            {selectedProject && (
                <div className="modal-backdrop" onClick={() => setSelectedProject(null)}>
                    <div className="modal-content" onClick={e => e.stopPropagation()}>
                        <div className="modal-header">
                            <div>
                                <h2>{selectedProject.projectName} Details
                                    <button className="user-list-btn" onClick={(e) => handleUserListClick(e)}>
                                        <FaFileAlt />
                                    </button>
                                    <button
                                        className="edit-project-btn"
                                        onClick={() => navigate('/dashboard/ProjectAssignment', { state: { projectId: selectedProject._id } })}
                                    >
                                        <FaEdit />
                                    </button>
                                    <button className="delete-project-btn" onClick={(e) => handleDeleteProjectClick(e, selectedProject._id)}>
                                        <MdDeleteForever />
                                    </button>
                                </h2>
                                <p>Deadline: {new Date(selectedProject.deadline).toLocaleDateString()}</p>
                            </div>
                            <div className="excel-url-button-container">
                                {selectedProject.excelUrl ? (
                                    <button className="beautiful-button" onClick={() => window.open(selectedProject.excelUrl, '_blank')}>Open Excel</button>
                                ) : (
                                    <button className="beautiful-button" onClick={() => setShowExcelUrlModal(true)}>Add Excel URL</button>
                                )}
                            </div>
                        </div>
                        {showDeleteConfirmModal && (
                            <div className="modal-backdrop" onClick={() => setShowDeleteConfirmModal(false)}>
                                <div className="delete-confirmation-modal" onClick={e => e.stopPropagation()}>
                                    <p>Are you sure you want to delete this project?</p>
                                    <button className="confirm-delete-btn" onMouseDown={startHold} onMouseUp={endHold} onMouseLeave={endHold}>
                                        Hold to Confirm
                                        <div className="confirm-delete-fill"></div>
                                    </button>
                                    <button className="cancel-delete-btn" onClick={() => setShowDeleteConfirmModal(false)}>Cancel</button>
                                </div>
                            </div>
                        )}
                        <div className="participants">
                            {selectedProject.tasks.map((task, taskIndex) => (
                                <div key={taskIndex} className="participant">
                                    <div className="participant-info">
                                        <img src={`${API_BASE_URL}/${task.profileImage}`} alt="Profile" className="participant-image" />
                                        <div>
                                            <h3 onClick={() => handleUserNameClick(task.userName)} style={{ cursor: 'pointer' }}>{task.userName}</h3>
                                            <p className="current-state-title">Current State</p>
                                            <p>{task.taskDescription}</p>
                                        </div>
                                    </div>
                                    <div className="status-container">
                                        <div className={getStatusIndicatorClass(task.status)}></div>
                                        {task.isCurrentUserAssigned && (
                                            <>
                                                <select className="status-select" value={task.status} onChange={(e) => handleStatusChange(e, taskIndex, task._id)}>
                                                    <option value="Not Done">Not Done</option>
                                                    <option value="Done">Done</option>
                                                    <option value="Problem">Problem</option>
                                                </select>
                                                <input
                                                    type="file"
                                                    multiple
                                                    onChange={(e) => handleFileAttach(e, taskIndex)}
                                                    style={{ display: 'none' }}
                                                    id={`file-input-${taskIndex}`}
                                                />
                                                <div className="Attach-File">
                                                    <label htmlFor={`file-input-${taskIndex}`} className="attach-file-btn">Attach File</label>
                                                </div>
                                            </>
                                        )}
                                        {!task.isCurrentUserAssigned && <p>{task.status}</p>}
                                    </div>
                                </div>
                            ))}
                            {showExcelUrlModal && (
                                <div className="excel-url-modal-backdrop" onClick={() => setShowExcelUrlModal(false)}>
                                    <div className="excel-url-modal-content" onClick={e => e.stopPropagation()}>
                                        <input type="text" placeholder="Enter Excel URL" ref={excelUrlRef} className="excel-url-input" />
                                        <button onClick={() => updateExcelUrl(selectedProject._id, excelUrlRef.current.value)}>Save</button>
                                        <button onClick={() => setShowExcelUrlModal(false)}>Close</button>
                                    </div>
                                </div>
                            )}
                            {showUserListModal && selectedProject && (
                                <div className="modal-backdrop" onClick={() => { setShowUserListModal(false); setCurrentView('userList'); }}>
                                    <div className="user-list-modal" onClick={(e) => e.stopPropagation()}>
                                        {currentView === 'userList' && (
                                            <>
                                                <div className="user-list-header">
                                                    <MdArrowBackIos className="back-icon" onClick={() => setShowUserListModal(false)} />
                                                    <h2>Project Participants</h2>
                                                    <label htmlFor="project-file-input" className="attach-file-btn">
                                                        <FaRegPlusSquare /> Attach Project File
                                                    </label>
                                                    <input
                                                        type="file"
                                                        multiple
                                                        onChange={(e) => handleFileAttach(e)}
                                                        style={{ display: 'none' }}
                                                        id="project-file-input"
                                                    />
                                                </div>
                                                <div className="user-cards-container">
                                                    <div className="user-card" onClick={() => { handleUserSectionClick(); setSelectUser("Project"); }}>
                                                        <FaFolderOpen className="folder-icon large-icon" />
                                                        <div className="user-name">Project Files</div>
                                                    </div>
                                                    {selectedProject.tasks.map((task, index) => (
                                                        <div key={index} className="user-card" onClick={() => { handleUserSectionClick(task._id); setSelectUser(task.userName); }}>
                                                            <img src={task.profileImage ? `${API_BASE_URL}/${task.profileImage}` : 'path/to/default/avatar'} alt="Profile" className="user-avatar" />
                                                            <div className="user-name">{task.userName}</div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                        )}
                                        {currentView === 'fileList' && (
                                            <>
                                                <div className="files-header">
                                                    <MdArrowBackIos className="back-icon" onClick={() => setCurrentView('userList')} />
                                                </div>
                                                <h2>{selectUser} Files</h2>
                                                <div>
                                                    {currentUserFiles.map((file, index) => {
                                                        const fileName = file.fileName.split('\\').pop();
                                                        return (
                                                            <div key={index} className="file-item">
                                                                <span onClick={() => downloadFile(file.filePath)}>{fileName}</span>
                                                                <RiDeleteBin5Fill className="delete-file-icon shake" onClick={() => handleDeleteFile(file._id, index)} />
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        <button onClick={() => setSelectedProject(null)}>Close</button>
                    </div>
                    <ToastContainer /> 
                </div>
            )}
            {showWipModal && (
                <div className="modal-backdrop" onClick={() => setShowWipModal(false)}>
                    <div className="modal-content" onClick={e => e.stopPropagation()}>
                        <div className="modal-header">
                            <h2>Work in Progress
                                <button className="add-button" onClick={() => setShowAddLinkModal(true)}>Add Link</button>
                            </h2>
                        </div>
                        <ul className="link-list">
                            {wipData.links.map(link => (
                                <li key={link._id} className="link-item">
                                    <a href={link.url} target="_blank" rel="noopener noreferrer" className="link-name">{link.name}</a>
                                    <RiDeleteBin5Fill className="delete-file-icon shake" onClick={() => deleteWipLink(link._id)} />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
            {showAddLinkModal && (
                <div className="modal-backdrop" onClick={() => setShowAddLinkModal(false)}>
                    <div className="modal-content" onClick={e => e.stopPropagation()}>
                        <h2>Add WIP Link</h2>
                        <input
                            type="text"
                            placeholder="Link Name"
                            value={newLinkName}
                            onChange={(e) => setNewLinkName(e.target.value)}
                            className="input-field"
                        />
                        <input
                            type="text"
                            placeholder="Link URL"
                            value={newLink}
                            onChange={(e) => setNewLink(e.target.value)}
                            className="input-field"
                        />
                        <button onClick={() => { addWipLink(newLink, newLinkName); setShowAddLinkModal(false); }}>Add</button>
                        <button onClick={() => setShowAddLinkModal(false)}>Close</button>
                    </div>
                </div>
            )}   
        </div>
    );
};

export default MyCampaigns;