import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/NavBar';
import  '../../styles/Sidebar.css';

const Layout = () => {
    const [isLightModeActive, setIsLightModeActive] = useState(true);
    const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);

    const toggleLightMode = () => {
        setIsLightModeActive(!isLightModeActive);
    };

    const toggleSidebar = () => {
        setIsSidebarExpanded(!isSidebarExpanded);
    };

  return (
    <body className={`dashboard-page ${isLightModeActive ? 'light-theme' : 'dark-theme'}`}>
     <div className="container">
        <Sidebar isExpanded={isSidebarExpanded} toggleSidebar={toggleSidebar} />
        <div className="main-content">
            <Navbar 
                        isLightModeActive={isLightModeActive} 
                        toggleLightMode={toggleLightMode} 
                        toggleSidebar={toggleSidebar}
                        isSidebarExpanded={isSidebarExpanded}
                    />
            <Outlet />
        </div>
    </div>
    </body>
  );
};

export default Layout;
