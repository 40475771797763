import React, { useState } from 'react';
import  '../../styles/Sidebar.css';
import  '../../styles/Dashboard.css';

import UserState from '../../components/UserState';


function UserStatePage() {
    return (
        <div className="dashboard-layout"> 
            <UserState />
        </div> 
    );
}

export default UserStatePage;
