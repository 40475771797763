import React, { useState } from 'react';
import axios from 'axios';
import '../styles/AddBusiness.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
const AddBusiness = () => {
    
    const [businessName, setBusinessName] = useState('');
    const [positions, setPositions] = useState(['']);

    const handlePositionChange = (index, event) => {
        const updatedPositions = positions.map((position, posIndex) => posIndex === index ? event.target.value : position);
        setPositions(updatedPositions);
    };

    const addPosition = () => {
        setPositions([...positions, '']);
    };

    const removePosition = index => {
        const updatedPositions = positions.filter((_, posIndex) => posIndex !== index);
        setPositions(updatedPositions);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${API_BASE_URL}/api/Business/addOrUpdate_Business`, {
                businessName,
                positions
            }, { withCredentials: true });
            toast.success('Business added/updated successfully!');
        } catch (error) {
            toast.error('Error adding/updating business!');
            // Handle error response
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className="add-business">
                <div className="form-container">
                    <div className="business-item">
                        <label>Business Name:</label>
                        <input type="text" className="input-item" value={businessName} onChange={(e) => setBusinessName(e.target.value)} />
                    </div>
                    <div className="position-item">
                        <label>Positions:</label>
                        {positions.map((position, index) => (
                            <div key={index} className="position-input">
                                <input type="text" value={position} onChange={(e) => handlePositionChange(index, e)} />
                                <button type="button" onClick={() => removePosition(index)}>Remove</button>
                            </div>
                        ))}
                        <button type="button" className="add-position-btn" onClick={addPosition}>Add Position</button>
                    </div>
                </div>
                <button type="submit" className="button submit-button">Submit</button>
            </form>
            <ToastContainer />
        </div>
    );
};

export default AddBusiness;
