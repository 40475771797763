import React, { useState } from 'react';
import  '../../styles/Sidebar.css';
import  '../../styles/Dashboard.css';
import AddBusiness from '../../components/AddBusiness';


function AddBusinessPage() {
    return (
        <div className="dashboard-layout"> 
            <AddBusiness />
        </div> 
    );
}

export default AddBusinessPage;

