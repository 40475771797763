import React from 'react';
import Login from '../components/Login';

function LoginPage() {
    return (
        <div>
            <Login />
        </div>
    );
}

export default LoginPage;
