import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Dashboard.css';
import '../styles/Sidebar.css';
import DogProfile from '../assets/dog.jpg';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const LeftDashboard = () => {
    const [activityLogs, setActivityLogs] = useState([]);

    useEffect(() => {
        const fetchActivityLogs = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/Activity/activity-logs`, { withCredentials: true });
                setActivityLogs(response.data);
            } catch (error) {
                console.error("Failed to fetch activity logs:", error);
            }
        };

        fetchActivityLogs();
    }, []);

    return (
        <div className="left-dashboard">
            <div className="recent-updates">
                <h2>Activity Log</h2>
                <div className="updates">
                    {activityLogs.map((log, index) => (
                        <div key={index} className="update">
                            <div className="profile-photo">
                                <img src={`${API_BASE_URL}/${log.profileImage}`} alt="Profile" />
                            </div>
                            <div className="message">
                                <p><b>{log.userName} {log.userLastName}</b> {log.activity}</p>
                                <small className="text-muted">Project: {log.projectName}</small>
                                <br />
                                <small className="text-muted">Update-At: {log.createdAt}</small>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LeftDashboard;
