import React, { useState } from 'react';
import  '../../styles/Sidebar.css';
import  '../../styles/Dashboard.css';
import Dashboard from '../../components/Dashboard';
import Left_Dashboard from '../../components/Left_Dashboard'
import AdminManager from '../../components/AdminManager';
import MyCampaigns from '../../components/My-Campaigns';
import ProjectAssignment from '../../components/ProjectAssignment';
import Sidebar from '../../components/Sidebar';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../../services/ProtectedRoute';

function AdminManagerPage() {
    return (
        <div className="dashboard-layout"> 
            <AdminManager />
        </div> 
    );
}

export default AdminManagerPage;
