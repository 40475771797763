import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import '../styles/ProjectAssignment.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { RxCross2 } from "react-icons/rx";
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
const ProjectAssignment = () => {
    const [projectName, setProjectName] = useState('');
    const [leader, setLeader] = useState(null); // Initialize leader as null for empty default
    const [description, setDescription] = useState('');
    // const [deadline, setDeadline] = useState('');
    const [deadline, setDeadline] = useState(new Date());

    const location = useLocation();
    const projectId = location.state?.projectId;
    const [shouldsetProjectId, setShouldsetProjectId] = useState(true); 
    // const [usersTasks, setUsersTasks] = useState([{ userId: '', userName: '', taskDescription: ''}]);
    // const [usersTasks, setUsersTasks] = useState([{ userId: '', userName: '', taskDescription: '', deadline: new Date(), unavailableDates: [] }]);
    const [usersTasks, setUsersTasks] = useState([{ taskId: uuidv4(), userId: '', userName: '', taskDescription: '', deadline: new Date(), unavailableDates: [] }]);
    const [businesses, setBusinesses] = useState([]);
    const [selectedBusiness, setSelectedBusiness] = useState('');
    const [businessesLoaded, setBusinessesLoaded] = useState(false);

    const fetchUnavailableDates = async (userId) => {
        try {
            // Call the API to get the unavailable dates for the specified user
            const response = await axios.get(`${API_BASE_URL}/api/projects/unavailable-dates/${userId}`);
            // The expected format of the response is an array of objects with 'fromDate' and 'toDate'
            const unavailableDatesRanges = response.data;
    
            let unavailableDates = [];
            unavailableDatesRanges.forEach(range => {
                let currentDate = new Date(range.fromDate);
                const endDate = new Date(range.toDate);
    
                // Loop through each day in the range and add it to the unavailableDates array
                while (currentDate <= endDate) {
                    unavailableDates.push(new Date(currentDate));
                    currentDate.setDate(currentDate.getDate() + 1);
                }
            });
    
            return unavailableDates;
        } catch (error) {
            console.error('Error fetching unavailable dates:', error);
            return [];
        }
    };

    const loadUserOptions = useCallback(async (inputValue) => {
        
        try {
            const response = await axios.get(`${API_BASE_URL}/api/users?search=${inputValue}`);
            return response.data.users.map(user => ({
                label: `${user.firstName} ${user.lastName} - ${user.assignedProjectsCount} tasks`,
                value: user._id,
                userName: `${user.firstName} ${user.lastName}`
            }));
        } catch (error) {
            console.error('Error fetching users:', error);
            return [];
        }
    }, []);

    useEffect(() => {
        
        const fetchBusinesses = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/Business/businesses`);
                setBusinesses(response.data.map(business => ({
                    label: business.BusinessName,
                    value: business.BusinessName,
                    positions: business.positions
                })));
                setBusinessesLoaded(true);
            } catch (error) {
                console.error('Error fetching businesses:', error);
            }
        };
        fetchBusinesses();
        const fetchProjectDetails = async () => {
            if (!projectId || !businessesLoaded) return;
        
            try {
                const response = await axios.get(`${API_BASE_URL}/api/projects/projects/${projectId}`);
                const project = response.data;
        
                setProjectName(project.projectName);
                setDescription(project.description);
                // Format the deadline date to be compatible with the date input
                setDeadline(new Date(project.deadline).toISOString().slice(0, 10));
                // Set leader using leaderId and leaderName from the response
                setLeader({ value: project.leaderId, userName: project.leaderName,label: project.leaderName });
                
                // Map tasks to format suitable for your component state
                const tasksWithUnavailableDates = await Promise.all(project.tasks.map(async task => {
                    const unavailableDates = await fetchUnavailableDates(task._id);
                    return {
                        taskId: uuidv4(),
                        userId: task._id,
                        userName: task.userName,
                        taskDescription: task.taskDescription,
                        deadline: new Date(task.deadline).toISOString().split('T')[0],
                        position: task.position,
                        unavailableDates: unavailableDates // Set the fetched unavailable dates here
                    };
                }));
                setUsersTasks(tasksWithUnavailableDates);

                // Set selectedBusiness based on the project details
                const matchingBusiness = businesses.find(business => business.label === project.business);
                if (matchingBusiness) {
                    setSelectedBusiness(matchingBusiness);
                }
                
                
        
            } catch (error) {
                console.error("Failed to fetch project details:", error);
            }
        };
        fetchProjectDetails();
    }, [projectId, loadUserOptions,businessesLoaded]);
    

    const handleBusinessChange = selectedOption => {
        setSelectedBusiness(selectedOption);
        // Reset usersTasks when business changes
        // setUsersTasks([{ userId: '', userName: '', taskDescription: '', position: '' }]);
    };

    const handlePositionChange = (index, field, value) => {
        const updatedTasks = usersTasks.map((task, taskIndex) => {
            if (index === taskIndex) {
                return { ...task, [field]: value };
            }
            return task;
        });
        setUsersTasks(updatedTasks);
    };

    
    const handleAddTask = () => {
        // setUsersTasks([...usersTasks, { userId: '', userName: '', taskDescription: '', deadline: new Date(), unavailableDates: [] }]);
        setUsersTasks([...usersTasks, { taskId: uuidv4(), userId: '', userName: '', taskDescription: '', deadline: new Date(), unavailableDates: [] }]);
    };

    const customSelectStyles = {
        menuList: (provided) => ({
          ...provided,
          maxHeight: '150px',
          overflowY: 'auto',
        }),
      };

    // const handleRemoveTask = (index) => {
    //     const tasks = [...usersTasks];
    //     tasks.splice(index, 1);
    //     setUsersTasks(tasks);
    // };
    const handleRemoveTask = (taskId) => {
        setUsersTasks(currentTasks => currentTasks.filter(task => task.taskId !== taskId));
    };

    const handleChangeTask = async (index, field, value) => {
        if (field === 'userId') {
            const unavailableDates = await fetchUnavailableDates(value.value);
            setUsersTasks(usersTasks.map((task, taskIndex) => {
                if (index === taskIndex) {
                    return { ...task, userId: value.value, userName: value.userName, unavailableDates };
                }
                return task;
            }));
        } else {
            setUsersTasks(usersTasks.map((task, taskIndex) => {
                if (index === taskIndex) {
                    return { ...task, [field]: value };
                }
                return task;
            }));
        }
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formattedTasks = usersTasks.map(task => ({
            userId: task.userId,
            userName: task.userName,
            taskDescription: task.taskDescription,
            position: task.position,
            deadline: task.deadline
        }));
    
        // Prepare the data to be sent in the request body
        const requestData = {
            projectName,
            leaderId: leader ? leader.value : null,
            leaderName: leader ? leader.userName : '',
            description,
            deadline,
            business: selectedBusiness ? selectedBusiness.value : '',
            usersTasks: formattedTasks
        };
    
        // Conditionally add projectId if it exists
        if (projectId && shouldsetProjectId) {
            requestData.projectId = projectId;
        }
    
        try {
            await axios.post(`${API_BASE_URL}/api/projects/add-project`, requestData);
            
            toast.success('Project and tasks added successfully!');
            setShouldsetProjectId(false)
            setProjectName('');
            setLeader(null);
            setDescription('');
            setDeadline(new Date());
            setSelectedBusiness('');
            setUsersTasks([{ taskId: uuidv4(), userId: '', userName: '', taskDescription: '', deadline: new Date(), unavailableDates: [] }]);
        } catch (error) {
            console.error('Error adding project:', error);
            toast.error('Failed to add project');
        }
    };
    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     const formattedTasks = usersTasks.map(task => ({
    //         userId: task.userId,
    //         userName: task.userName,
    //         taskDescription: task.taskDescription,
    //         position: task.position,
    //         deadline: task.deadline
    //     }));

    //     try {
    //         await axios.post('http://localhost:5000/api/projects/add-project', {
    //             projectName,
    //             leaderId: leader ? leader.value : null,
    //             leaderName: leader ? leader.userName : '',
    //             description,
    //             deadline,
    //             business: selectedBusiness ? selectedBusiness.value : '',
    //             usersTasks: formattedTasks
    //         });
    //         alert('Project and tasks added successfully!');
    //     } catch (error) {
    //         console.error('Error adding project:', error);
    //         alert('Failed to add project');
    //     }
    // };

    return (
        <form onSubmit={handleSubmit}>    
            <div className="project-assignment">
                
                <h2>Assign New Project</h2>
                <div className="grid-container">
                    <div className="item1">
                        <label htmlFor="projectName">Project Name:</label>
                        <input
                            id="projectName"
                            type="text"
                            value={projectName}
                            onChange={(e) => setProjectName(e.target.value)}
                            required
                        />
                    </div>

                    <div className="item2">
                        <label htmlFor="leader">Leader:</label>
                        <AsyncSelect
                            className='width95'
                            cacheOptions
                            loadOptions={loadUserOptions}
                            onChange={setLeader}
                            defaultOptions
                            onFocus={loadUserOptions}
                            placeholder="Select Leader"
                            styles={customSelectStyles}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                            value={leader}
                        />
                    </div>

                    <div className="item3">
                        <label htmlFor="description">Remark:</label>
                        <textarea
                            id="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                        />
                    </div>

                    <div className="item4">
                        <label htmlFor="deadline">Project Deadline (optional):</label>
                        <input
                            id="deadline"
                            type="date" // Using a date input for easy date picking
                            value={deadline}
                            onChange={(e) => setDeadline(e.target.value)}
                        />
                    </div>
                    <div>
                        <label htmlFor="business">Business:</label>
                        <Select
                            className='width95 marginbottom'
                            options={businesses}
                            name="business"
                            onChange={handleBusinessChange}
                            placeholder="Select Business"
                            value={selectedBusiness}
                        />
                    </div>
                </div>

                    {/* <div className='grid-four-item'> */}
                        {usersTasks.map((task, index) => (
                            
                            // <Grid xs={4} spacing={2}>
                        // <div className='grid-four-item'>
                        
                            <div key={task.taskId} className="task-assignment">
                                <div className='grid-user-item'>
                                <label>User:</label>
                                <label>Task Description for User:</label>
                                <label>Position:</label>
                                <label>Deadline:</label>
                                </div>
                                <div className='grid-user-item'>
                                <AsyncSelect
                                    className='width95 marginbottom'
                                    cacheOptions
                                    loadOptions={loadUserOptions}
                                    defaultOptions
                                    onFocus={loadUserOptions}
                                    onChange={(selectedOption) => handleChangeTask(index, 'userId', selectedOption)}
                                    placeholder="Select User"
                                    styles={customSelectStyles}
                                    getOptionLabel={(option) => option.label}
                                    getOptionValue={(option) => option.value}
                                    value={{ label: task.userName, value: task.userId }}
                                    
                                />
                                
                                <input
                                    type="text"
                                    value={task.taskDescription}
                                    onChange={(e) => handleChangeTask(index, 'taskDescription', e.target.value)}
                                    required
                                />
                                <Select
                                    className='width95 marginbottom'
                                    options={selectedBusiness ? selectedBusiness.positions.map(position => ({
                                        label: position,
                                        value: position
                                    })) : []}
                                    name="position"
                                    onChange={option => handlePositionChange(index, 'position', option.value)}
                                    placeholder="Select Position"
                                    styles={customSelectStyles}
                                    value={{ label: task.position, value: task.position }}
                                />
                                <DatePicker
                                selected={task.deadline}
                                onChange={(date) => handleChangeTask(index, 'deadline', date)}
                                dateFormat="yyyy/MM/dd"
                                dayClassName={(date) => {
                                    // Function to check if `date` is in `task.unavailableDates`
                                    const isUnavailable = task.unavailableDates.some(unavailableDate =>
                                    unavailableDate.getDate() === date.getDate() &&
                                    unavailableDate.getMonth() === date.getMonth() &&
                                    unavailableDate.getFullYear() === date.getFullYear()
                                    );

                                    // Return the custom class name if the date is to be highlighted
                                    return isUnavailable ? 'custom-highlighted' : undefined;
                                }}
                                />
                                {/* <DatePicker
                                    selected={task.deadline}
                                    onChange={(date) => handleChangeTask(index, 'deadline', date)}
                                    highlightDates={task.unavailableDates}
                                    dateFormat="yyyy/MM/dd"
                                    /> */}
                                 
                                {usersTasks.length > 1 && (
                                    // <button className="remove-button" type="button" onClick={() => handleRemoveTask(index)}><RxCross2 /></button>
                                    <button className="remove-button" type="button" onClick={() => handleRemoveTask(task.taskId)}><RxCross2 /></button>
                                )}
                                </div>
                            
                            </div>
                        // </div>
                        ))}

                    {/* </div> */}
                <button className='grid-one-item width95' type="button" onClick={handleAddTask}>Add Task</button>
                <button className='grid-one-item width95' type="submit">Submit Project</button>
                
            </div>
            <ToastContainer />    
         </form>
        
    );
};

export default ProjectAssignment;
