import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Dashboard.css';
import '../styles/Sidebar.css';
import '../styles/information-center.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Lottie from 'lottie-react';
import newSvg from '../assets/new_animation.json';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const InformationCenter = () => {
  const [files, setFiles] = useState([]);
  const [activeCategory, setActiveCategory] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [uploadCategory, setUploadCategory] = useState('holiday');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [userRole, setUserRole] = useState([]);
  const [editingFileId, setEditingFileId] = useState(null);
  const [editingFilename, setEditingFilename] = useState('');
  const [originalFilename, setOriginalFilename] = useState('');
  const [newFilesCategories, setNewFilesCategories] = useState([]);

  useEffect(() => {
    fetchUserRole();
    fetchNewFilesCategories();
  }, []);

  useEffect(() => {
    if (activeCategory) {
      fetchFiles(activeCategory);
      
    }
  }, [activeCategory]);
  
  const fetchUserRole = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/users/role`, { withCredentials: true });
      setUserRole(response.data.role);
    } catch (error) {
      console.error('Error fetching user role:', error);
    }
  };

  const fetchFiles = async (category) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/info/files/${category}`);
      setFiles(response.data);
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };
  const fetchNewFilesCategories = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/info/new-files-categories`);
      setNewFilesCategories(response.data);
      console.log("log",response.data)
    } catch (error) {
      console.error('Error fetching new files categories:', error);
    }
  };

  const handleFileClick = (category) => {
    setActiveCategory(category);
    setIsEditing(false); // Reset editing mode
  };

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
    setFileNames(files.map(file => file.name));
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('originalNames', fileNames.map(encodeURIComponent).join(','));
    formData.append('category', uploadCategory);
    selectedFiles.forEach((file, index) => {
      formData.append('files', file);
    });

    try {
      const response = await axios.post(`${API_BASE_URL}/api/info/files`, formData, { withCredentials: true });
      fetchFiles(activeCategory); // Refresh files
      toggleForm();
      toast.success('File added successfully!');
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const confirmDeleteFile = (file) => {
    setFileToDelete(file);
    setShowDeleteModal(true);
  };

  const handleDeleteFile = async () => {
    try {
      await axios.delete(`${API_BASE_URL}/api/info/files/${fileToDelete._id}`, { withCredentials: true });
      fetchFiles(activeCategory); // Refresh files
      setShowDeleteModal(false);
      toast.success('File deleted successfully!');
    } catch (error) {
      console.error('Error deleting file:', error);
      toast.error('Failed to delete file.');
    }
  };

  const handleDownload = (filepath) => {
    if (!isEditing) {
      const url = `${API_BASE_URL}/${filepath}`;
      window.open(url, '_blank');
    }
  };

  const allowedRoles = ['ADMIN-CCD-TECH', 'CEO', 'ADMIN-CCD', 'MD'];
  const canEdit = userRole.some(role => allowedRoles.includes(role));

  const isFileNew = (createdAt) => {
    const fileDate = new Date(createdAt);
    const currentDate = new Date();
    const differenceInDays = (currentDate - fileDate) / (1000 * 60 * 60 * 24);
    console.log("differenceInDays",differenceInDays)
    return differenceInDays <= 7;
  };
  const handleRenameFile = async (fileId, newFilename) => {
    try {
      await axios.patch(`${API_BASE_URL}/api/info/files/${fileId}/rename`, { filename: newFilename }, { withCredentials: true });
      fetchFiles(activeCategory); // Refresh files
      toast.success('Filename updated successfully!');
    } catch (error) {
      console.error('Error renaming file:', error);
      toast.error('Failed to rename file.');
    }
  };

  const handleFilenameChange = (event) => {
    setEditingFilename(event.target.value);
  };

  const handleFilenameKeyDown = async (event, fileId) => {
    if (event.key === 'Enter') {
      await handleRenameFile(fileId, event.target.value);
      setIsEditing(false);
      setEditingFileId(null);
    }
  };

  const handleFilenameBlur = async (fileId) => {
    if (editingFilename !== originalFilename) {
      await handleRenameFile(fileId, editingFilename);
    }
    setIsEditing(false);
    setEditingFileId(null);
  };

  return (
    <div className="information-center">
      <div className="top-bar">
        <div className="buttons-container">
          <div className="info-button-wrapper">
            <button className={`info-button ${activeCategory === 'holiday' ? 'active' : ''}`} onClick={() => handleFileClick('holiday')}>
              Annual Holiday Schedule
            </button>
            {newFilesCategories.includes('holiday') && (
              <div className="new-category-svg">
                <Lottie animationData={newSvg} loop={true} />
              </div>
            )}
          </div>
          <div className="info-button-wrapper">
            <button className={`info-button ${activeCategory === 'employment' ? 'active' : ''}`} onClick={() => handleFileClick('employment')}>
              MQDC Employment Terms 
            </button>
            {newFilesCategories.includes('employment') && (
              <div className="new-category-svg">
                <Lottie animationData={newSvg} loop={true} />
              </div>
            )}
          </div>
          <div className="info-button-wrapper">
            <button className={`info-button ${activeCategory === 'general' ? 'active' : ''}`} onClick={() => handleFileClick('general')}>
              General Information 
            </button>
            {newFilesCategories.includes('general') && (
              <div className="new-category-svg">
                <Lottie animationData={newSvg} loop={true} />
              </div>
            )}
          </div>
        </div>
        {canEdit && (
          <div className="edit-buttons">
            <button className="edit-button" onClick={() => setIsEditing(!isEditing)}>
              Edit
            </button>
            <button className="edit-button" onClick={toggleForm}>
              Add File
            </button>
          </div>
        )}
      </div>
      <div className={`files-container ${activeCategory ? 'show' : ''}`}>
        {files.map(file => (
          <div key={file._id} className="file-card-link" onClick={() => handleDownload(file.filepath)}>
            <div className="file-card">
              <div className="file-thumbnail-wrapper">
                <img src={`${API_BASE_URL}/${file.thumbnail}`} alt="Thumbnail" className="file-thumbnail" />
                {isFileNew(file.createdAt) && (
                  <div className="new-file-svg">
                    <Lottie animationData={newSvg} loop={true} />
                  </div>
                )}
              </div>
              <div className="file-info">
                {isEditing && editingFileId === file._id ? (
                  <input
                    type="text"
                    className="edit-filename-input"
                    value={editingFilename}
                    onBlur={() => handleFilenameBlur(file._id)}
                    onKeyDown={(e) => handleFilenameKeyDown(e, file._id)}
                    onChange={handleFilenameChange}
                    autoFocus
                  />
                ) : (
                  <p className="file-name" onClick={() => { setEditingFileId(file._id); setEditingFilename(file.filename); setOriginalFilename(file.filename); }}>{file.filename}</p>
                )}
                {isEditing && (
                  <button className="delete-button" onClick={(e) => { e.stopPropagation(); confirmDeleteFile(file); }}>
                    🗑️
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      {showForm && (
        <div className="modal-background" onClick={toggleForm}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <form onSubmit={handleUpload}>
              <label>
                File:
                <input type="file" name="files" multiple onChange={handleFileChange} />
              </label>
              <label>
                Category:
                <select name="category" value={uploadCategory} onChange={e => setUploadCategory(e.target.value)}>
                  <option value="holiday">Annual Holiday Schedule</option>
                  <option value="employment">MQDC Employment Terms</option>
                  <option value="general">General Information</option>
                </select>
              </label>
              <button type="submit">Upload</button>
            </form>
          </div>
        </div>
      )}
      {showDeleteModal && (
        <div className="modal-background" onClick={() => setShowDeleteModal(false)}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <p>Are you sure you want to delete this file?</p>
            <button onClick={handleDeleteFile}>Confirm</button>
            <button onClick={() => setShowDeleteModal(false)}>Cancel</button>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default InformationCenter;
