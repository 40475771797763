import React, { useState } from 'react';
import  '../../styles/Sidebar.css';
import  '../../styles/Dashboard.css';
import  '../../styles/LeaveApproval.css';
import LeaveApproval_page from '../../components/LeaveApproval';

function LeaveApproval() {
    return (
        <div className="Campaign-layout"> 
            <LeaveApproval_page />
        </div> 
    );
}

export default LeaveApproval;
