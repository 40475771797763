import React, { useState } from 'react';
import  '../../styles/Sidebar.css';
import  '../../styles/Dashboard.css';
import  '../../styles/RequestForms.css';
import RequestForms_page from '../../components/RequestForms';

function RequestForms() {
    return (
        <div className="Campaign-layout"> 
            <RequestForms_page />
        </div> 
    );
}

export default RequestForms;
