import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Dashboard.css';
import '../styles/Sidebar.css';
import '../styles/Evaluations.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Evaluations = () => {
    const [isActive, setIsActive] = useState(false);
    const [evaluationLink, setEvaluationLink] = useState('');
    const [selectedForm, setSelectedForm] = useState('');

    useEffect(() => {
        const fetchActiveFunction = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/active-function/Evaluations-status`);
                setIsActive(response.data.status);
                setEvaluationLink(response.data.link);
            } catch (error) {
                console.error('Error fetching evaluation status:', error);
            }
        };

        fetchActiveFunction();
    }, []);

    const handleFormChange = (event) => {
        setSelectedForm(event.target.value);
    };

    const handleDownload = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/requestform/download-evaluation-form`, { responseType: 'blob' });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', decodeURIComponent('CDOFrom-003_ใบประเมินผลทดลองงาน_พนงทั่วไป.pdf'));
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
            toast.error('Failed to download file.');
        }
    };

    return (
        <div className="request-forms-container">
            <div className="header-container">
                <h1>Evaluations</h1>
                <select className="form-dropdown" value={selectedForm} onChange={handleFormChange}>
                    <option value="" disabled>Select an evaluation form</option>
                    <option value="probation">แบบประเมินทดลองงาน</option>
                    {isActive && <option value="annual">แบบประเมินผลการปฏิบัติงานประจำปี</option>}
                </select>
            </div>
            <div className="form-details">
                {selectedForm === 'probation' && (
                    <button className="submit-button" onClick={handleDownload}>Download Probation Evaluation</button>
                )}
                {selectedForm === 'annual' && isActive && (
                    <div className="google-form-note">
                        <p>กรุณาคลิกที่ลิงก์นี้: <a href={evaluationLink} target="_blank" rel="noopener noreferrer">Google Form</a></p>
                    </div>
                )}
            </div>
            <ToastContainer />
        </div>
    );
};

export default Evaluations;
