// import React from 'react';
// import { Navigate } from 'react-router-dom';
// import { jwtDecode } from 'jwt-decode';

// const ProtectedRoute = ({ children, requiredRole }) => {
//   const token = localStorage.getItem('token');
//   let userRole = null;

//   if (!token) {
//     // User not authenticated
//     return <Navigate to="/login" />;
//   }

//   if (requiredRole) {
//     const decodedToken = jwtDecode(token);
//     userRole = decodedToken.role;

//     if (!requiredRole.includes(userRole)) {
//       // User does not have the required role
//       return <Navigate to="/dashboard" />;
//     }
//   }

//   return children;  // User is authenticated and, if required, has the correct role
// };

// export default ProtectedRoute;

import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
axios.defaults.withCredentials = true;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL 
const ProtectedRoute = ({ children, requiredRole }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hasRequiredRole, setHasRequiredRole] = useState(false);
  const [isVerifying, setIsVerifying] = useState(true);
  // if (!requiredRole || requiredRole.some(role => response.data.roles.includes(role))) {
  useEffect(() => {
    const verifyRole = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/auth/verifyRole`, { withCredentials: true });
        if (response.data.isAuthenticated) {
          setIsAuthenticated(true);
        }
        if (!requiredRole || requiredRole.some(role => response.data.roles.includes(role))) {
          setHasRequiredRole(true);
        }
      } catch (error) {
        console.error('Error verifying role:', error.response ? error.response.data : error);
      } finally {
        setIsVerifying(false);
      }
    };

    verifyRole();
  }, [requiredRole]);
  if (isVerifying) {
    return <div>Verifying...</div>; // Or any loading indicator
  }
  
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  if (!hasRequiredRole) {
    return <Navigate to="/dashboard" />;
  }
  

  return children;
};

export default ProtectedRoute;
