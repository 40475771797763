import React, { useState } from 'react';
import axios from 'axios';
import '../styles/Dashboard.css';
import '../styles/Sidebar.css';
import '../styles/RequestForms.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const formOptions = [
    "เอกสารรับรองการทำงาน",
    "เอกสารรับรองเงินเดือน",
    "เอกสารรับรองการทำงาน (สำหรับทำวีซ่า)",
    "แบบฟอร์มเบิกจ่ายค่าเดินทางสำหรับการจัดงานอีเว้นต์",
    "เอกสารขอลาออก",
    "สวัสดิการสำหรับการช่วยเหลืองานฌาปนกิจ"
];

const RequestForms = () => {
    const [selectedForm, setSelectedForm] = useState('');
    const [reason, setReason] = useState('');
    const [thVersion, setThVersion] = useState(false);
    const [engVersion, setEngVersion] = useState(false);
    const [versionError, setVersionError] = useState(false);
    const [selectedCheckbox, setSelectedCheckbox] = useState('');
    const [files, setFiles] = useState({});
    const [fileError, setFileError] = useState('');
    const [isHospitality, setIsHospitality] = useState(false);

    const handleFormChange = (event) => {
        setSelectedForm(event.target.value);
        setReason('');
        setThVersion(false);
        setEngVersion(false);
        setVersionError(false);
        setSelectedCheckbox('');
        setFiles({});
        setFileError('');
        setIsHospitality(false);
    };

    const handleCheckboxChange = (event) => {
        setSelectedCheckbox(event.target.value);
    };

    const handleFileChange = (event) => {
        const { name, files } = event.target;
        setFiles((prevFiles) => ({ ...prevFiles, [name]: files[0] }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setFileError('');

        if (["เอกสารรับรองการทำงาน", "เอกสารรับรองเงินเดือน"].includes(selectedForm) && !thVersion && !engVersion) {
            setVersionError(true);
            return;
        }
        setVersionError(false);

        // Validate file uploads
        let requiredFiles = [];
        switch (selectedCheckbox) {
            case "กรณี คู่สมรสเสียชีวิต ให้แนบสำเนาทะเบียนสมรส":
                requiredFiles = ["deathCertificate", "houseRegistration", "idCard", "recipientHouseRegistration", "recipientIdCard", "marriageCertificate"];
                break;
            case "กรณี บิดา/มารดาเสียชีวิต (หากเป็นบิดา มารดาบุญธรรม) ให้แนบหนังสือรับรองบิดา มารดาบุญธรรมจากทางราชการ หรือจากการรับรองจากทางผู้ใหญ่บ้าน /กำนัน":
                requiredFiles = ["deathCertificate", "houseRegistration", "idCard", "adoptionCertificate", "recipientHouseRegistration", "recipientIdCard"];
                break;
            default:
                break;
        }

        const missingFiles = requiredFiles.filter(fileKey => !files[fileKey]);

        if (missingFiles.length > 0) {
            setFileError('กรุณาอัปโหลดเอกสารให้ครบถ้วน');
            return;
        }

        try {
            const formData = new FormData();
            const originalNames = [];

            for (const key in files) {
                const encodedFileName = encodeURIComponent(files[key].name);
                originalNames.push(encodedFileName);
                formData.append('files', files[key]);
            }

            formData.append('originalNames', JSON.stringify(originalNames));
            formData.append('document_type', selectedForm);
            formData.append('reason', reason);
            formData.append('thVersion', thVersion);
            formData.append('engVersion', engVersion);
            formData.append('selectedCheckbox', selectedCheckbox);

            await axios.post(`${API_BASE_URL}/api/requestform/request-form`, formData, { withCredentials: true });
            toast.success('Form submitted successfully!');
            setSelectedForm('');
            setReason('');
            setThVersion(false);
            setEngVersion(false);
            setSelectedCheckbox('');
            setFiles({});
            setIsHospitality(false);
        } catch (error) {
            console.error('Error submitting form:', error);
            toast.error('Failed to submit form.');
        }
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = `${API_BASE_URL}/api/requestform/download-resignation-form?isHospitality=${isHospitality}`;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="request-forms-container">
            <div className="header-container">
                <h1>Request Forms</h1>
                <select
                    className="form-dropdown"
                    value={selectedForm}
                    onChange={handleFormChange}
                >
                    <option value="" disabled>Select a form</option>
                    {formOptions.map((form, index) => (
                        <option key={index} value={form}>{form}</option>
                    ))}
                </select>
            </div>
            <div className="form-details">
                {selectedForm && (
                    <form onSubmit={handleSubmit} className="request-form">
                        {["เอกสารรับรองการทำงาน", "เอกสารรับรองเงินเดือน", "เอกสารรับรองการทำงาน (สำหรับทำวีซ่า)"].includes(selectedForm) && (
                            <>
                                <div className="form-group">
                                    <label htmlFor="reason">เหตุผลในการขอ:</label>
                                    <textarea
                                        id="reason"
                                        value={reason}
                                        onChange={(e) => setReason(e.target.value)}
                                        required
                                        className="textarea-input"
                                    />
                                </div>
                                {["เอกสารรับรองการทำงาน", "เอกสารรับรองเงินเดือน"].includes(selectedForm) && (
                                    <div className="checkbox-group">
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={thVersion}
                                                onChange={(e) => setThVersion(e.target.checked)}
                                            />
                                            TH Version
                                        </label>
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={engVersion}
                                                onChange={(e) => setEngVersion(e.target.checked)}
                                            />
                                            ENG Version
                                        </label>
                                    </div>
                                )}
                                {versionError && (
                                    <p className="error-text">กรุณาเลือกอย่างน้อยหนึ่งเวอร์ชัน (TH หรือ ENG)</p>
                                )}
                                {selectedForm === "เอกสารรับรองการทำงาน (สำหรับทำวีซ่า)" && (
                                    <div className="visa-note">
                                        <p>หรือคลิกที่ลิงก์นี้: <a href="https://forms.gle/VJE8ZC5C7EvDWbZHA" target="_blank" rel="noopener noreferrer">Google Form</a></p>
                                    </div>
                                )}
                                <button type="submit" className="submit-button">Submit</button>
                                <div className="note">
                                    <p>หมายเหตุ</p>
                                    <p>1. ระยะเวลาในการจัดทำเอกสารประมาณ 3-5 วันทำการ</p>
                                    <p>2. บริษัทฯจะส่งเอกสารทุกวันที่ 15 และ 30 ของทุกเดือน</p>
                                </div>
                            </>
                        )}
                        {selectedForm === "เอกสารขอลาออก" && (
                            <>
                                <div className="resignation-note">
                                    <p className="bold-text">ขั้นตอน สำหรับผู้ที่ประสงค์ขอยื่นใบลาออกจากการเป็นพนักงานของบริษัท</p>
                                    <p className="bold-text">· การแจ้งล่วงหน้า</p>
                                    <p>Ø พนักงานแจ้งผู้บังคับบัญชางานเพื่อทราบก่อนยื่นหนังสือลาออก</p>
                                    <p className="bold-text">· การส่งมอบงาน</p>
                                    <p>Ø ให้พนักงานปรึกษาผู้บังคับบัญชาว่าจะให้ใครมารับช่วงต่องานส่วนของท่าน ( ส่งมอบงาน และ/หรือส่งต่อไฟล์สำคัญที่ต้องใช้ในการทำงานไว้ให้คนที่รับผิดชอบงานต่อ)</p>
                                    <p className="bold-text">· การยื่นหนังสือลาออก</p>
                                    <p>Ø พนักงานยื่นหนังสือลาออกอย่างเป็นทางการ โดยจะต้องยื่นล่วงหน้าไม่น้อยกว่า 30 วัน (ตามกฎหมายแรงงานการบอกยกเลิกสัญญาจ้าง)</p>
                                    <p>Ø ส่งหนังสือลาออกมายัง E-mail : natpaphats_su@dtgo.com เพื่อเสนออนุมัติการลาออกของพนักงาน</p>
                                    <p className="bold-text">· ฝ่ายทรัพยากรบุคคล ออกหนังสือรับรองการทำงาน (ตามประมวลกฎหมายแพ่งและพาณิชย์ มาตรา 585)</p>
                                    <p className="bold-text">· การส่งคืนทรัพย์สินของบริษัท</p>
                                    <p>Ø ในวันสุดท้ายของการทำงาน ให้พนักงานคืนทรัพย์สินของบริษัททั้งหมดที่อาคารสรชัย ชั้น 16 ติดต่อ คุณสกาวเดือน คำสวัสดิ์ (รอฮาน) 092-269-7043 เช่น อุปกรณ์อิเล็กทรอนิก โทรศัพท์มือถือ แล็ปท็อป บัตรประกันสุขภาพกลุ่ม</p>
                                </div>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={isHospitality}
                                        onChange={(e) => setIsHospitality(e.target.checked)}
                                    />
                                     Hospitality department
                                </label>
                                <button type="button" className="download-button-Leave" onClick={handleDownload}>Download Resignation Form</button>
                            </>
                        )}
                        {selectedForm === "สวัสดิการสำหรับการช่วยเหลืองานฌาปนกิจ" && (
                            <>
                                <div className="funeral-note">
                                    <p>ผู้ที่ประสงค์ขอเบิกเงินสวัสดิการสวัสดิการสำหรับการช่วยเหลืองานฌาปนกิจพนักงาน หรือบุคคลในครอบครัว จะต้องยื่นเอกสารหลักฐานให้แก่หน่วยงาน CDO เพื่อทำบันทึกข้อความเรื่อง การจัดสวัสดิการสำหรับการช่วยเหลืองานฌาปนกิจ</p>
                                    <p className="bold-text">เอกสารแนบ</p>
                                    <p>ได้แก่ สำเนาใบมรณะ / สำเนาบัตรประชาชนผู้ขอรับเงินและผู้วายชนม์ / สำเนาทะเบียนบ้านผู้ขอรับเงินและผู้วายชนม์</p>
                                    <p className="bold-text">หมายเหตุ</p>
                                    <p>บุคคลในครอบครัว หมายถึง บุตรที่ให้กำเนิดตามกฎหมายไม่นับรวมบุตรบุญธรรม คู่สมรสตามกฎหมาย และบิดา-มารดา</p>
                                </div>
                                <div className="checkbox-group">
                                    <label>
                                        <input
                                            type="radio"
                                            name="funeralType"
                                            value="กรณี คู่สมรสเสียชีวิต ให้แนบสำเนาทะเบียนสมรส"
                                            checked={selectedCheckbox === "กรณี คู่สมรสเสียชีวิต ให้แนบสำเนาทะเบียนสมรส"}
                                            onChange={handleCheckboxChange}
                                        />
                                        กรณี คู่สมรสเสียชีวิต ให้แนบสำเนาทะเบียนสมรส
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            name="funeralType"
                                            value="กรณี บิดา/มารดาเสียชีวิต (หากเป็นบิดา มารดาบุญธรรม) ให้แนบหนังสือรับรองบิดา มารดาบุญธรรมจากทางราชการ หรือจากการรับรองจากทางผู้ใหญ่บ้าน /กำนัน"
                                            checked={selectedCheckbox === "กรณี บิดา/มารดาเสียชีวิต (หากเป็นบิดา มารดาบุญธรรม) ให้แนบหนังสือรับรองบิดา มารดาบุญธรรมจากทางราชการ หรือจากการรับรองจากทางผู้ใหญ่บ้าน /กำนัน"}
                                            onChange={handleCheckboxChange}
                                        />
                                        กรณี บิดา/มารดาเสียชีวิต (หากเป็นบิดา มารดาบุญธรรม) ให้แนบหนังสือรับรองบิดา มารดาบุญธรรมจากทางราชการ หรือจากการรับรองจากทางผู้ใหญ่บ้าน /กำนัน
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            name="funeralType"
                                            value="กรณี บุตร/ธิดา (ไม่นับรวมบุตรบุญธรรม)"
                                            checked={selectedCheckbox === "กรณี บุตร/ธิดา (ไม่นับรวมบุตรบุญธรรม)"}
                                            onChange={handleCheckboxChange}
                                        />
                                        กรณี บุตร/ธิดา (ไม่นับรวมบุตรบุญธรรม)
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            name="funeralType"
                                            value="กรณี พนักงานเสียชีวิต ให้หัวหน้างานเป็นผู้แจ้งและดำเนินการเท่านั้น"
                                            checked={selectedCheckbox === "กรณี พนักงานเสียชีวิต ให้หัวหน้างานเป็นผู้แจ้งและดำเนินการเท่านั้น"}
                                            onChange={handleCheckboxChange}
                                        />
                                        กรณี พนักงานเสียชีวิต ให้หัวหน้างานเป็นผู้แจ้งและดำเนินการเท่านั้น
                                    </label>
                                </div>
                                {selectedCheckbox === "กรณี คู่สมรสเสียชีวิต ให้แนบสำเนาทะเบียนสมรส" && (
                                    <>
                                        <div className="file-upload-group">
                                            <label htmlFor="deathCertificate">เอกสารหลักฐานของผู้วายชนม์ (ใบมรณะ):</label>
                                            <input
                                                type="file"
                                                id="deathCertificate"
                                                name="deathCertificate"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                        <div className="file-upload-group">
                                            <label htmlFor="houseRegistration">เอกสารหลักฐานของผู้วายชนม์ (สำเนาทะเบียนบ้าน):</label>
                                            <input
                                                type="file"
                                                id="houseRegistration"
                                                name="houseRegistration"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                        <div className="file-upload-group">
                                            <label htmlFor="idCard">เอกสารหลักฐานของผู้วายชนม์ (สำเนาบัตรประชาชน):</label>
                                            <input
                                                type="file"
                                                id="idCard"
                                                name="idCard"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                        <div className="file-upload-group">
                                            <label htmlFor="recipientHouseRegistration">เอกสารหลักฐานของผู้ขอรับ (สำเนาทะเบียนบ้าน):</label>
                                            <input
                                                type="file"
                                                id="recipientHouseRegistration"
                                                name="recipientHouseRegistration"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                        <div className="file-upload-group">
                                            <label htmlFor="recipientIdCard">เอกสารหลักฐานของผู้ขอรับ (สำเนาบัตรประชาชน):</label>
                                            <input
                                                type="file"
                                                id="recipientIdCard"
                                                name="recipientIdCard"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                        <div className="file-upload-group">
                                            <label htmlFor="marriageCertificate">เอกสารหลักฐานคู่สมรสที่เสียชีวิต (สำเนาทะเบียนสมรส):</label>
                                            <input
                                                type="file"
                                                id="marriageCertificate"
                                                name="marriageCertificate"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                        <button type="submit" className="submit-button">Submit</button>
                                    </>
                                )}
                                {selectedCheckbox === "กรณี บิดา/มารดาเสียชีวิต (หากเป็นบิดา มารดาบุญธรรม) ให้แนบหนังสือรับรองบิดา มารดาบุญธรรมจากทางราชการ หรือจากการรับรองจากทางผู้ใหญ่บ้าน /กำนัน" && (
                                    <>
                                        <div className="file-upload-group">
                                            <label htmlFor="deathCertificate">เอกสารหลักฐานของผู้วายชนม์ (ใบมรณะ):</label>
                                            <input
                                                type="file"
                                                id="deathCertificate"
                                                name="deathCertificate"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                        <div className="file-upload-group">
                                            <label htmlFor="houseRegistration">เอกสารหลักฐานของผู้วายชนม์ (สำเนาทะเบียนบ้าน):</label>
                                            <input
                                                type="file"
                                                id="houseRegistration"
                                                name="houseRegistration"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                        <div className="file-upload-group">
                                            <label htmlFor="idCard">เอกสารหลักฐานของผู้วายชนม์ (สำเนาบัตรประชาชน):</label>
                                            <input
                                                type="file"
                                                id="idCard"
                                                name="idCard"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                        <div className="file-upload-group">
                                            <label htmlFor="adoptionCertificate">เอกสารหลักฐานของผู้วายชนม์ (หนังสือรับรองบิดา มารดา บุญธรรมจากทางราชการ):</label>
                                            <input
                                                type="file"
                                                id="adoptionCertificate"
                                                name="adoptionCertificate"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                        <div className="file-upload-group">
                                            <label htmlFor="recipientHouseRegistration">เอกสารหลักฐานของผู้ขอรับ (สำเนาทะเบียนบ้าน):</label>
                                            <input
                                                type="file"
                                                id="recipientHouseRegistration"
                                                name="recipientHouseRegistration"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                        <div className="file-upload-group">
                                            <label htmlFor="recipientIdCard">เอกสารหลักฐานของผู้ขอรับ (สำเนาบัตรประชาชน):</label>
                                            <input
                                                type="file"
                                                id="recipientIdCard"
                                                name="recipientIdCard"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                        <button type="submit" className="submit-button">Submit</button>
                                    </>
                                )}
                                {selectedCheckbox === "กรณี บุตร/ธิดา (ไม่นับรวมบุตรบุญธรรม)" && (
                                    <div className="google-form-note">
                                        <p>กรุณาคลิกที่ลิงก์นี้: <a href="https://forms.gle/VjCdALuZNHjE3S3U7" target="_blank" rel="noopener noreferrer">Google Form</a></p>
                                    </div>
                                )}
                                {selectedCheckbox === "กรณี พนักงานเสียชีวิต ให้หัวหน้างานเป็นผู้แจ้งและดำเนินการเท่านั้น" && (
                                    <div className="google-form-note">
                                        <p>กรุณาคลิกที่ลิงก์นี้: <a href="https://forms.gle/VjCdALuZNHjE3S3U7" target="_blank" rel="noopener noreferrer">Google Form</a></p>
                                    </div>
                                )}
                                {fileError && (
                                    <p className="error-text">{fileError}</p>
                                )}
                                {versionError && (
                                    <p className="error-text">กรุณาเลือกอย่างน้อยหนึ่งเวอร์ชัน (TH หรือ ENG)</p>
                                )}
                            </>
                        )}
                    </form>
                )}
            </div>
            <ToastContainer />
        </div>
    );
};

export default RequestForms;
