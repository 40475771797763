import React, { useState } from 'react';
import  '../../styles/Sidebar.css';
import  '../../styles/Dashboard.css';
import Dashboard from '../../components/Dashboard';
import Left_Dashboard from '../../components/Left_Dashboard'
import AdminManager from '../../components/AdminManager';
import MyCampaigns from '../../components/My-Campaigns';
import ProjectAssignment from '../../components/ProjectAssignment';
import Sidebar from '../../components/Sidebar';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../../services/ProtectedRoute';

function DashboardPage() {
    const [isLightModeActive, setIsLightModeActive] = useState(false);

    const toggleLightMode = () => {
        setIsLightModeActive(!isLightModeActive);
        // Here you can add logic to handle light mode activation
    };
    return (  
        <div className="dashboard-layout">     
            <Dashboard isLightModeActive={isLightModeActive} />
            <Left_Dashboard isLightModeActive={isLightModeActive} toggleLightMode={toggleLightMode} />
        </div>
     
       
    );
}

export default DashboardPage;
