import React, { useState } from 'react';
import  '../../styles/Sidebar.css';
import  '../../styles/Dashboard.css';
import  '../../styles/MyCampaing.css';
import MyCampaigns from '../../components/My-Campaigns';

function MyCampaignsPages() {
    return (
        <div className="Campaign-layout"> 
            <MyCampaigns />
        </div> 
    );
}

export default MyCampaignsPages;
