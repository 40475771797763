import React, { useState } from 'react';
import  '../../styles/Sidebar.css';
import  '../../styles/Dashboard.css';
import  '../../styles/Evaluations.css';
import Evaluations_page from '../../components/Evaluations';

function Evaluations() {
    return (
        <div className="Campaign-layout"> 
            <Evaluations_page />
        </div> 
    );
}

export default Evaluations;
