import React, { useState } from 'react';
import  '../../styles/Sidebar.css';
import  '../../styles/Dashboard.css';
import Profile from '../../components/Profile';

function UserProfilePage() {
    return (
        <div className="dashboard-layout"> 
            <Profile />
        </div> 
    );
}

export default UserProfilePage;
