import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/AdminManager.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
const AdminManager = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [roles, setRoles] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [nickname, setNickname] = useState('');
  const [profileImage, setProfileImage] = useState(null);

  const [business, setBusiness] = useState('');
  const [businessChoices, setBusinessChoices] = useState([]);
  const [playerCode, setPlayerCode] = useState('');
  const [position, setPosition] = useState('');

  useEffect(() => {
    const fetchBusinesses = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/Business/businesses`, { withCredentials: true });
        setBusinessChoices(response.data);
      } catch (error) {
        console.error('Error fetching businesses:', error);
      }
    };
    fetchBusinesses();
  }, []);

  const handleRoleChange = (role) => {
    const updatedRoles = roles.includes(role) 
      ? roles.filter(r => r !== role) 
      : [...roles, role];
    setRoles(updatedRoles);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    roles.forEach(role => formData.append('roles', role));
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('nickname', nickname);
    formData.append('business', business);
    formData.append('player_code', playerCode);
    formData.append('position', position);
    if (profileImage) {
      formData.append('profileImage', profileImage, profileImage.name);
    }
    
    try {
      await axios.post(`${API_BASE_URL}/api/users/add-user`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      }, { withCredentials: true });
      toast.success('User added successfully');
      // Reset form fields after successful submission
    } catch (error) {
      toast.error('Failed to add user');
    }
  };

  return (
    <div className="admin-manager">
      <h2>Add New User</h2>
      <form onSubmit={handleSubmit} className="admin-manager-form" action="#">
        <div className="form-group">
          <label>Email:</label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </div>

        <div className="form-group">
          <label>Password:</label>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        </div>

        <div className="form-group">
          <label>Business:</label>
          <select value={business} onChange={(e) => setBusiness(e.target.value)}>
            <option value="">Select a Business</option>
            {businessChoices.map((choice) => (
              <option key={choice._id} value={choice.BusinessName}>
                {choice.BusinessName}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group roles-group">
          <label>Roles:</label>
          <div className="roles-checkboxes">
            {['PLAYER', 'PROJECT-CHAMPION', 'MD', 'ADMIN-CCD','ADMIN-CCD-TECH'].map((role) => (
              <div className="role-checkbox" key={role}>
                <input
                  type="checkbox"
                  id={`role-${role}`}
                  value={role}
                  checked={roles.includes(role)}
                  onChange={() => handleRoleChange(role)}
                />
                <label htmlFor={`role-${role}`}>{role}</label>
              </div>
            ))}
          </div>
        </div>

        <div className="form-group">
          <label>First Name:</label>
          <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
        </div>

        <div className="form-group">
          <label>Last Name:</label>
          <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
        </div>

        <div className="form-group">
          <label>Nickname:</label>
          <input type="text" value={nickname} onChange={(e) => setNickname(e.target.value)} />
        </div>

        <div className="form-group">
          <label>Player Code:</label>
          <input type="text" value={playerCode} onChange={(e) => setPlayerCode(e.target.value)} />
        </div>

        <div className="form-group">
          <label>Position:</label>
          <input type="text" value={position} onChange={(e) => setPosition(e.target.value)} />
        </div>

        <div className="form-group">
          <label>Profile Image:</label>
          <input type="file" onChange={(e) => setProfileImage(e.target.files[0])} />
        </div>

        <button type="submit" className="submit-btn">Add User</button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AdminManager;
