import React, { useState, useEffect } from 'react';
import '../styles/Dashboard.css';
import '../styles/Sidebar.css';
import axios from 'axios';
import { MdAnalytics } from "react-icons/md";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Dashboard = ({ isLightModeActive }) => {
    const [tasks, setTasks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [upcomingProjects, setUpcomingProjects] = useState([]);

    const navigate = useNavigate();

    const handleProjectClick = (projectId) => {
        navigate('/dashboard/my-campaigns', { state: { selectedProjectId: projectId } });
    };

    useEffect(() => {
        const fetchTasks = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${API_BASE_URL}/api/projects/my-tasks`, { withCredentials: true });
                setTasks(response.data);
            } catch (error) {
                console.error('Error fetching tasks:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchTasks();
        const fetchUpcomingProjects = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${API_BASE_URL}/api/projects/upcoming-projects`, { withCredentials: true });
                setUpcomingProjects(response.data);
            } catch (error) {
                console.error('Error fetching upcoming projects:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUpcomingProjects();
    }, []);

    return (
        <main>
            <h1>DASHBOARD</h1>
            <div className="insights">
                {upcomingProjects.slice(0, 3).map((project, index) => (
                    <div key={index} className={`project${index + 1}`} onClick={() => handleProjectClick(project._id)}>
                        <div className='top-up-coming'>
                          <span><MdAnalytics size={32} /></span>
                          <div className="circular-progress-container">
                                  <CircularProgressbar
                                      value={project.tasks.filter(task => task.status === "Done").length / project.tasks.length * 100}
                                      text={`${Math.round(project.tasks.filter(task => task.status === "Done").length / project.tasks.length * 100)}%`}
                                      background
                                      backgroundPadding={6}
                                      styles={{
                                          root: {},
                                          path: {
                                              stroke: `var(--color-primary)`,
                                              strokeLinecap: 'round',
                                              transition: 'stroke-dashoffset 0.5s ease 0s',
                                          },
                                          trail: {
                                              stroke: 'var(--color-info-light)',
                                              strokeLinecap: 'round',
                                          },
                                          text: {
                                              fill: 'var(--color-dark)',
                                              fontSize: '16px',
                                              dominantBaseline: 'middle',
                                              textAnchor: 'middle',
                                          },
                                          background: {
                                              fill: 'var(--color-background)',
                                          },
                                      }}
                                  />
                            </div>
                      </div>    
                        <div className="middle">
                            <div className="left">
                                <h3>{project.projectName}</h3>
                                <h1>{project.description}</h1>
                            </div>
                            
                        </div>
                        <small className="text-muted">Upcoming Deadline: {new Date(project.deadline).toLocaleDateString()}</small>
                    </div>
                ))}
            </div>
            <div className="recent-order">
                <h2>My Tasks</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Project Name</th>
                            <th>Project Leader</th>
                            <th>My Job</th>
                            <th>Deadline</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <tr><td colSpan="5">Loading tasks...</td></tr>
                        ) : tasks.length > 0 ? (
                            tasks.map((task, index) => (
                                <tr key={index}>
                                    <td>{task.projectName}</td>
                                    <td>{task.leaderName}</td>
                                    <td>{task.taskDescription}</td>
                                    <td className={task.deadline ? "" : "warning"}>{task.deadline || "N/A"}</td>
                                    <td className={`status ${task.status.replace(/\s+/g, '-').toLowerCase()}`}>{task.status}</td>
                                    <td className="primary">Details</td>
                                </tr>
                            ))
                        ) : (
                            <tr><td colSpan="5">No tasks found</td></tr>
                        )}
                    </tbody>
                </table>
                {tasks.length > 0 && <a href="#">Show all</a>}
            </div>
        </main>
    );
};

export default Dashboard;
