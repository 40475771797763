import React, { useState } from 'react';
import  '../../styles/Sidebar.css';
import  '../../styles/Dashboard.css';
import  '../../styles/information-center.css';
import InformationCenter from '../../components/information_center';

function Information_Center() {
    return (
        <div className="Campaign-layout"> 
            <InformationCenter />
        </div> 
    );
}

export default Information_Center;
