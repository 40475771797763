import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Dashboard.css';
import '../styles/Sidebar.css';
import '../styles/RequestReview.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const documentTypeOptions = [
    "เอกสารรับรองการทำงาน",
    "เอกสารรับรองเงินเดือน",
    "เอกสารรับรองการทำงาน (สำหรับทำวีซ่า)",
    "เอกสารสั่งซื้อคอมพิวเตอร์",
    "แบบฟอร์มเบิกจ่ายค่าเดินทางสำหรับการจัดงานอีเว้นต์",
    "สวัสดิการสำหรับการช่วยเหลืองานฌาปนกิจ"
];

const businessOptions = [
    "Corporate Culture Development",
    "MD",
    "Entertainment Business Unit",
    "Events & Exhibitions Business Unit",
    "Hospitality Business Unit",
    "Strategy & Business Solutions Unit",
    "Finance & Accounting"
];

const statusColors = {
    'รออนุมัติ': 'yellow',
    'อนุมัติ': 'green',
    'ยกเลิก': 'red'
};

const RequestReview = () => {
    const [requests, setRequests] = useState([]);
    const [search, setSearch] = useState('');
    const [businessFilter, setBusinessFilter] = useState('');
    const [documentTypeFilter, setDocumentTypeFilter] = useState('');

    const fetchRequests = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/requestform/request-forms-review`, {
                params: { search, businessFilter, documentTypeFilter },
                withCredentials: true
            });
            setRequests(response.data);
        } catch (error) {
            console.error('Error fetching request forms:', error);
            toast.error('Failed to fetch request forms.');
        }
    };

    useEffect(() => {
        fetchRequests();
    }, [search, businessFilter, documentTypeFilter]);

    const handleDownload = async (type, version, userId, requestId) => {
        try {
            let endpoint = `${API_BASE_URL}/api/requestform/generate-doc/${type}/${version}/${userId}`;
            const response = await axios.get(endpoint, { withCredentials: true });
            const { filePath } = response.data;
            const decodedPath = decodeURIComponent(filePath);
            window.open(`${API_BASE_URL}/${decodedPath}`);
            toast.success('Document downloaded successfully!');
        } catch (error) {
            console.error('Error downloading document:', error);
            toast.error('Failed to download document.');
        }
    };

    const handleStatusChange = async (id, status) => {
        try {
            await axios.put(`${API_BASE_URL}/api/requestform/request-form-status/${id}/status`, { status }, { withCredentials: true });
            toast.success('Status updated successfully!');
            fetchRequests();
        } catch (error) {
            console.error('Error updating status:', error);
            toast.error('Failed to update status.');
        }
    };

    return (
        <div className="request-review-container">
            <div className="header-container">
                <h1>Request Review</h1>
                <div className="filters-container">
                    <input
                        type="text"
                        placeholder="Search by name or player code"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        className="search-input"
                    />
                    <select
                        className="filter-dropdown"
                        value={businessFilter}
                        onChange={(e) => setBusinessFilter(e.target.value)}
                    >
                        <option value="">All Businesses</option>
                        {businessOptions.map((business, index) => (
                            <option key={index} value={business}>{business}</option>
                        ))}
                    </select>
                    <select
                        className="filter-dropdown"
                        value={documentTypeFilter}
                        onChange={(e) => setDocumentTypeFilter(e.target.value)}
                    >
                        <option value="">All Document Types</option>
                        {documentTypeOptions.map((type, index) => (
                            <option key={index} value={type}>{type}</option>
                        ))}
                    </select>
                </div>
            </div>
            <table className="request-table">
                <thead>
                    <tr>
                        <th className="optional-column">#</th>
                        <th className="optional-column">รหัสพนักงาน</th>
                        <th>ชื่อ</th>
                        <th>นามสกุล</th>
                        <th>ประเภทเอกสาร</th>
                        <th className="optional-column">หน่วยงาน</th>
                        <th className="optional-column">ตำแหน่ง</th>
                        <th className="optional-column">เหตุผล</th>
                        <th className="optional-column">Download</th>
                        <th>ยื่นวันที่</th>
                        <th>สถานะ</th>
                    </tr>
                </thead>
                <tbody>
                    {requests.map((request, index) => (
                        <tr key={request._id}>
                            <td className="optional-column">{index + 1}</td>
                            <td className="optional-column">{request.userId.player_code}</td>
                            <td>{request.userId.firstName}</td>
                            <td>{request.userId.lastName}</td>
                            <td>{request.document_type}</td>
                            <td className="optional-column">{request.userId.business}</td>
                            <td className="optional-column">{request.userId.position}</td>
                            <td className="reason-cell optional-column">
                                <div className="reason-popup">
                                    {request.reason}
                                </div>
                                {request.reason.length > 20 ? `${request.reason.slice(0, 20)}...` : request.reason}
                            </td>
                            <td className="optional-column">
                                {(request.version.th || request.version.eng || request.document_type === "สวัสดิการสำหรับการช่วยเหลืองานฌาปนกิจ") && (
                                    <>
                                        {request.version.th && (
                                            <button onClick={() => handleDownload(request.document_type, 'th', request.userId._id, request._id)}>Download TH</button>
                                        )}
                                        {request.version.eng && (
                                            <button onClick={() => handleDownload(request.document_type, 'eng', request.userId._id, request._id)}>Download ENG</button>
                                        )}
                                        {request.document_type === "สวัสดิการสำหรับการช่วยเหลืองานฌาปนกิจ" && (
                                            <button onClick={() => handleDownload(request.document_type, null, request.userId._id, request._id)}>Download</button>
                                        )}
                                    </>
                                )}
                            </td>
                            <td>{new Date(request.timestamp).toLocaleString('th-TH')}</td>
                            <td>
                                <div className="status-container">
                                    <span className="status-dot" style={{ backgroundColor: statusColors[request.status] }}></span>
                                    <select
                                        value={request.status}
                                        onChange={(e) => handleStatusChange(request._id, e.target.value)}
                                    >
                                        <option value="รออนุมัติ">รออนุมัติ</option>
                                        <option value="อนุมัติ">อนุมัติ</option>
                                        <option value="ยกเลิก">ยกเลิก</option>
                                    </select>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <ToastContainer />
        </div>
    );
};

export default RequestReview;
