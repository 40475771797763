import React, { useState } from 'react';
import  '../../styles/Sidebar.css';
import  '../../styles/Dashboard.css';
import RequestFormReview from '../../components/RequestFormReview';

function RequestFormReviewPage() {
    return (
        <div className="dashboard-layout"> 
            <RequestFormReview />
        </div> 
    );
}

export default RequestFormReviewPage;
